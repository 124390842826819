import { useEffect, useState } from "react";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

import Navbar from "components/Landing/Navbar";
import Background from "components/Landing/Background";
import About from "components/Landing/About";
import CloseCTA from "components/Landing/CloseCTA";
import Footer from "components/Landing/Footer";
import Header from "components/Landing/Header";
import TopicSearch from "components/Landing/TopicSearch";
import ActionGenerator from "components/Landing/ActionGenerator";
import { TopSearch } from "components/Landing/TopSearch";
import { loadDrift } from "utils/drift";
import { ActNowButton, RandomActionButton } from "components/common";
import { TOPICS } from "constants/topics";

const LandingPage = () => {
  const router = useRouter();
  const [randomTopicLink, setRandomTopicLink] = useState("");

  const generateRandomTopicLink = () => {
    const randomTopic = TOPICS[Math.floor(Math.random() * TOPICS.length)];

    router.push(`/search/${encodeURI(randomTopic.name.split(" ").join("+"))}`);
  };

  return (
    <>
      <main className="relative overflow-x-hidden ">
        <div className="mt-[80px] flex min-h-[calc(100vh-80px)] flex-col items-center justify-center ">
          <div className="flex w-screen flex-col items-center overflow-y-auto md:top-[calc(25%-53px)]">
            <ActNowButton
              onClick={generateRandomTopicLink}
              width={100}
              height={100}
            />
            <Header />
            <div className="mt-10">
              <TopicSearch />
            </div>
            <ActionGenerator />
            <RandomActionButton />

            <TopSearch />
          </div>
        </div>

        <Navbar />
        <Footer />
      </main>
    </>
  );
};

export default LandingPage;
