import { useState } from "react";
import Image from "next/image";

import { ActNowButton } from "components/common";

const ActionGenerator = () => {
  return (
    <div className="mt-10 flex w-screen items-center justify-center sm:w-[50vw] md:mt-0"></div>
  );
};

export default ActionGenerator;
