import Link from "next/link";

import { ActionItem } from "components/common";
import { TOP_SEARCH } from "./constants";

const TopSearch = () => {
  return (
    <div className="mt-20 flex flex-col px-4 pb-[80px]">
      <h3 className="text-base font-normal text-gray-500">
        Action of the day <span className="text-gray-400">for</span>{" "}
        <Link
          className="underline"
          href={`/search/${TOP_SEARCH.topic}`}
        >{`${TOP_SEARCH.readableTopic}`}</Link>
      </h3>

      <ActionItem
        showSummary
        title={TOP_SEARCH.title}
        description={TOP_SEARCH.description}
        homeLink={TOP_SEARCH.homeLink}
        donateLink={TOP_SEARCH.donateLink}
      />
    </div>
  );
};

export { TopSearch };
