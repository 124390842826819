import { useState, FormEvent } from "react";
import { toast } from "react-toastify";

import { LANDING_BOTTOM_NAVIGATION_OPTIONS } from "./constants";
import { validateEmail } from "utils/textUtils";
import BasicToast from "components/common/Layout/BasicToast";
import { TOAST_IDS } from "constants/toastConstants";
import axios from "axios";
import { RAY_EMAIL } from "constants/contacts";

function Footer() {
  const [subscribePlaceholder, setSubscribePlaceholder] = useState(
    "Subscribe to our newsletter"
  );
  const [email, setEmail] = useState<string>("");

  const handleSubscribeFocus = () =>
    setSubscribePlaceholder("Enter your email");
  const handleSubscribeBlur = () =>
    setSubscribePlaceholder("Subscribe to our newsletter");

  const handleEmailChange = (e: FormEvent<HTMLInputElement>) =>
    setEmail(e.currentTarget.value);

  const handleOnSubscribe = async () => {
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address", {
        containerId: TOAST_IDS.basicToastId,
      });
    } else {
      await axios.post("/api/newsletterEmails", { email });
      await fetch("/api/sendgrid", {
        body: JSON.stringify({
          to: RAY_EMAIL,
          from: {
            email: RAY_EMAIL,
            name: email,
          },
          subject: `New email subscription from ${email}`,
          text: `Email: ${email}`,
          html: `<div>Email: ${email}</div>`,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      setEmail("");

      toast.success(`Subscribed!`, {
        containerId: TOAST_IDS.basicToastId,
      });
    }
  };
  return (
    <footer className="box-border flex h-auto w-screen items-center justify-center border-t border-gray-200 px-5 py-2 sm:overflow-y-hidden md:absolute md:right-0 md:bottom-0 md:left-0">
      <div className="flex w-full flex-col items-center justify-between md:flex-row md:items-center">
        <div className="flex flex-row items-center justify-start">
          <nav className="mt-6 flex flex-col items-center justify-center sm:mt-0 md:flex-row md:gap-y-8">
            {LANDING_BOTTOM_NAVIGATION_OPTIONS.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={
                  "rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100"
                }
              >
                {item.name}
              </a>
            ))}
          </nav>
        </div>

        <div className="mt-6 w-full items-center justify-end md:mt-0 md:flex md:max-w-md">
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            type="email"
            name="email-address"
            id="email-address"
            autoComplete="email"
            onFocus={handleSubscribeFocus}
            onBlur={handleSubscribeBlur}
            onChange={handleEmailChange}
            value={email}
            className="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 text-base text-gray-900 shadow-sm placeholder:text-gray-400 focus:border-emerald-600 focus:outline-offset-0 sm:text-sm md:w-80 md:leading-6 xl:w-full"
            placeholder={subscribePlaceholder}
          />
          <div className=" md:ml-4">
            <button
              disabled={!email}
              onClick={handleOnSubscribe}
              type="button"
              className="mt-2 flex w-full items-center justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:cursor-not-allowed disabled:opacity-70 md:mt-0"
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <BasicToast enableMultiToast={true} />
    </footer>
  );
}

export default Footer;
